<template>
    <div class="container mx-auto w-full p-1 ">
      <!-- User Service Agreement -->
      <section id="user-service-agreement" class="mb-12">
        <h1 class="text-4xl font-bold mb-4">User Agreement</h1>
        <p class="text-sm text-gray-500 mb-8"><strong>Effective Date:</strong> 5th October 2024</p>
  
        <h2 class="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
        <p class="text-gray-700 mb-6">
          By accessing or using ExprLabs ("the Service"), you agree to comply with and be bound by this Agreement. If
          you do not agree to these terms, you should not use the Service.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">2. Description of Service</h2>
        <p class="text-gray-700 mb-6">
          ExprLabs provides artificial intelligence-based tools to assist in academic peer review. The Service is
          available via a subscription plan and requires users to register an account.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">3. Eligibility</h2>
        <p class="text-gray-700 mb-6">
          To use ExprLabs, you must be at least 18 years old and capable of forming a binding contract under applicable
          law. By using the Service, you represent that you meet these eligibility requirements.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">4. User Accounts</h2>
        <p class="text-gray-700 mb-4">
          <strong>Account Registration:</strong> To access the Service, you must create an account. You agree to provide
          accurate, current, and complete information during the registration process.
        </p>
        <p class="text-gray-700 mb-4">
          <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account
          credentials. You agree to notify ExprLabs immediately of any unauthorized use of your account.
        </p>
        <p class="text-gray-700 mb-6">
          <strong>Account Termination:</strong> ExprLabs reserves the right to suspend or terminate your account at any
          time, with or without cause, and with or without notice.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">5. Subscription and Fees</h2>
        <p class="text-gray-700 mb-4">
          <strong>Subscription Plans:</strong> ExprLabs offers various subscription plans. By subscribing, you agree to
          pay all applicable fees in accordance with the plan you select.
        </p>
        <p class="text-gray-700 mb-4">
          <strong>Payment:</strong> All fees are billed in advance and are non-refundable. You authorize ExprLabs to
          charge your payment method for any subscription fees.
        </p>
        <p class="text-gray-700 mb-6">
          <strong>Changes to Pricing:</strong> ExprLabs may change the pricing for the Service at any time. Any pricing
          changes will apply at the start of your next billing cycle after receiving notice from ExprLabs.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">6. User Content and Data</h2>
        <p class="text-gray-700 mb-6">
          <strong>User-Generated Content:</strong> Any content, including feedback, peer review materials, and other data
          you upload to the Service, remains your property. By uploading content, you grant ExprLabs a limited,
          non-exclusive, and revocable license to use your content for the purpose of providing the Service.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">7. Use of the Service</h2>
        <p class="text-gray-700 mb-4">
          <strong>License:</strong> ExprLabs grants you a limited, non-transferable, and revocable license to use the
          Service for your personal or academic purposes in accordance with this Agreement.
        </p>
        <p class="text-gray-700 mb-6">
          <strong>Prohibited Activities:</strong> You agree not to violate any applicable law, regulation, or third-party
          right. You agree not to use the Service for any illegal or unauthorized purpose, reverse engineer the Service,
          or share your subscription with unauthorized users.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">8. Intellectual Property</h2>
        <p class="text-gray-700 mb-6">
          All intellectual property, including but not limited to trademarks, logos, and the ExprLabs platform itself, is
          owned by ExprLabs. You agree not to infringe upon these intellectual property rights.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">9. Disclaimer of Warranties</h2>
        <p class="text-gray-700 mb-6">
          ExprLabs provides the Service on an "as-is" basis. To the fullest extent allowed by law, ExprLabs disclaims all
          warranties, express or implied.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">10. Limitation of Liability</h2>
        <p class="text-gray-700 mb-6">
          To the fullest extent permitted by law, ExprLabs shall not be liable for any indirect, incidental, or
          consequential damages, including loss of profits, loss of data, or any other damages arising from your use of
          the Service.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">11. Indemnification</h2>
        <p class="text-gray-700 mb-6">
          You agree to indemnify and hold ExprLabs harmless from any claims, damages, liabilities, and expenses arising
          from your use of the Service or your violation of this Agreement.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">12. Governing Law</h2>
        <p class="text-gray-700 mb-6">
          This Agreement shall be governed by and construed in accordance with the laws of England and Wales. Services provided by EXPRLABS LTD.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">13. Modifications to the Agreement</h2>
        <p class="text-gray-700 mb-6">
          ExprLabs reserves the right to modify this Agreement at any time. Your continued use of the Service constitutes
          your acceptance of the modified Agreement.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">14. Termination</h2>
        <p class="text-gray-700 mb-6">
          ExprLabs reserves the right to terminate this Agreement and your access to the Service at any time, without
          notice.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">15. Contact Information</h2>
        <p class="text-gray-700">
          If you have any questions, please contact us at
          <a href="mailto:support@exprlabs.com" class="text-blue-500 hover:underline">support@exprlabs.com</a>.
        </p>
      </section>
  
      <!-- Privacy Policy -->
      <section id="privacy-policy">
        <h1 class="text-4xl font-bold mb-4">Privacy Policy</h1>
        <p class="text-sm text-gray-500 mb-8"><strong>Effective Date:</strong> 5th October 2024</p>
  
        <h2 class="text-2xl font-semibold mb-4">1. Information We Collect</h2>
        <p class="text-gray-700 mb-4">We collect the following information:</p>
        <ul class="list-disc list-inside mb-6 text-gray-700">
          <li><strong>Personal Information:</strong> Your name and email address.</li>
          <li><strong>Payment Information:</strong> Payment details for subscription billing.</li>
          <li><strong>Usage Data:</strong> Information on how you use the Service.</li>
        </ul>
  
        <h2 class="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
        <p class="text-gray-700 mb-6">
          We use your information for the following purposes:
        </p>
        <ul class="list-disc list-inside mb-6 text-gray-700">
          <li><strong>Providing Services:</strong> To create and manage your account.</li>
          <li><strong>Communication:</strong> To send account-related notifications.</li>
          <li><strong>Improving the Service:</strong> To analyze and improve our services.</li>
          <li><strong>Security:</strong> To detect and prevent fraud or security breaches.</li>
        </ul>
  
        <h2 class="text-2xl font-semibold mb-4">3. Sharing Your Information</h2>
        <p class="text-gray-700 mb-6">
          We do not sell or rent your personal information. We may share your information in the following cases:
        </p>
        <ul class="list-disc list-inside mb-6 text-gray-700">
          <li><strong>Service Providers:</strong> Third-party providers who assist in operating the Service.</li>
          <li><strong>Legal Compliance:</strong> When required to comply with the law.</li>
        </ul>
  
        <h2 class="text-2xl font-semibold mb-4">4. Data Security</h2>
        <p class="text-gray-700 mb-6">
          We implement industry-standard security measures to protect your data, but no method is 100% secure.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">5. Data Retention</h2>
        <p class="text-gray-700 mb-6">
          We retain your personal information as long as your account is active or as necessary to comply with legal
          obligations.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">6. Your Rights</h2>
        <p class="text-gray-700 mb-6">
          You have the right to access, correct, or delete your personal information. To exercise these rights, contact
          us at <a href="mailto:support@exprlabs.com" class="text-blue-500 hover:underline">support@exprlabs.com</a>.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">7. Cookies and Tracking Technologies</h2>
        <p class="text-gray-700 mb-6">
          We use cookies to enhance your user experience. You can control cookie settings in your browser.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">8. Changes to the Privacy Policy</h2>
        <p class="text-gray-700 mb-6">
          We may update this Privacy Policy from time to time. Please review it periodically.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">9. Children's Privacy</h2>
        <p class="text-gray-700 mb-6">
          ExprLabs does not knowingly collect information from children under 18. If we discover such data, we will delete
          it.
        </p>
  
        <h2 class="text-2xl font-semibold mb-4">10. Contact Information</h2>
        <p class="text-gray-700">
          If you have any questions, contact us at
          <a href="mailto:support@exprlabs.com" class="text-blue-500 hover:underline">support@exprlabs.com</a>.
        </p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: "TermsAndPrivacy",
  };
  </script>
  
  <style scoped>
  /* No additional styles needed, all handled by Tailwind */
  </style>
  