<template>
    <footer class="border-t border-gray-200 bg-gray-800 py-5 w-full mt-5">
      <div class="flex justify-between mt-4 mx-10">
        <div class="flex flex-col space-y-2 font-thin text-gray-50">
            <span>EXPRLABS LTD 2024</span>
            <p>AI-powered solutions connecting curious people and businesses to the World of Expert Knowledge</p>
          

        </div>
        <div class="flex flex-col space-y-2">
          
 
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent'
  };
  </script>
  
  <style scoped>
  </style>
  